<template>
  <div class="add-bank">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">Add bank card</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content">
      <van-image
        width="100%"
        v-if="show"
        :src="require('./img/img1.png')"
        @click="show = false"
      />

      <van-image
        width="100%"
        v-else
        :src="require('./img/img2.png')"
        @click="show = true"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.add-bank {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__title {
      overflow: initial;
    }

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .content {
    padding: 1.25rem 1.5rem 0;
  }
}
</style>